import React, { useState } from "react";
import { BiLogOut } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../firebaseAuth/authContext";

import {
  IoChatboxEllipsesOutline,
  IoCloseCircleOutline,
  IoFolderOpenOutline,
  IoHelp,
  IoHomeOutline,
  IoPeopleOutline,
  IoSearchOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import { PiSquaresFourThin } from "react-icons/pi";
import defaultProfilePic from "../../assets/DefaultProfile.png";
import styles from "../../styles/navbar/navbar.module.scss";

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { authUser, logout } = useAuth();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    console.log("click");
  };
  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("../Auth/login/LoginPage.jsx");
    } catch (error) {
      console.error("Error during logout: ", error);
    }
  };
  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 992) {
      setShowMenu(false);
    }
  };

  return (
      <div>
        <div
          className={`${styles.nav__menu} ${showMenu ? styles.showMenu : ""}`}
          id="nav-menu"
        >
          <header className={styles.headerContainer}>
            <div className={styles.profileSection}>
              <div className={styles.profileInfo}>
                <img
                  src={defaultProfilePic}
                  alt="Profile-img"
                  className={styles.profileImage}
                />
              </div>
              <div className={styles.userInfo}>
                <p className={styles.userName}>
                  {authUser ? authUser.displayName : "Not signed in"}
                </p>
                <p className={styles.role}>
                  {authUser?.occupation ? authUser.occupation : "Student"}
                </p>
              </div>
            </div>
          </header>
          <nav className={styles.navbarContainer}>
            <div className={styles.navItemsBackground}></div>
            <ul className={styles.navItems}>
              <li>
                <i>
                  <IoHomeOutline />
                </i>
                <a href="/home" onClick={closeMenuOnMobile}>
                  Home
                </a>
              </li>
              <li>
                <i>
                  <IoSearchOutline />
                </i>
                <a href="/explore" onClick={closeMenuOnMobile}>
                  Explore
                </a>
              </li>
              <li>
                <i>
                  <IoChatboxEllipsesOutline />
                </i>
                <a href="/messages" onClick={closeMenuOnMobile}>
                  Chats
                </a>
              </li>
              <li>
                <i>
                  <IoFolderOpenOutline />
                </i>
                <a href="/myProjects" onClick={closeMenuOnMobile}>
                  My Projects
                </a>
              </li>
              <li>
                <i>
                  <IoPeopleOutline />
                </i>
                <a href="/collaboration" onClick={closeMenuOnMobile}>
                  Collaborations
                </a>
              </li>
              <hr />
            </ul>
            <ul className={styles.accessibility}>
              <div className={styles.accessibilityBackground}></div>
              <li>
                <i>
                  <IoSettingsOutline />
                </i>
                <a href="/settings" onClick={closeMenuOnMobile}>
                  Settings
                </a>
              </li>
              <li>
                <i>
                  <IoHelp />
                </i>
                <a href="/help" onClick={closeMenuOnMobile}>
                  help
                </a>
              </li>
              <hr />
            </ul>
            <div className={styles.logoutContainer}>
              <div className={styles.logoutBackground}></div>
              <button className={styles.logoutButton} onClick={handleLogout}>
                <i>
                  <BiLogOut />
                </i>
                Logout
              </button>
            </div>
          </nav>
          <div
            className={styles.nav__close}
            id="nav-close"
            onClick={toggleMenu}
          >
            <IoCloseCircleOutline />
          </div>
        </div>
        <div className={styles.closeBackground}></div>
        <div
          style={{ fontSize: "36px" }}
          className={styles.nav__toggle}
          id="nav-toggle"
          onClick={toggleMenu}
        >
          <PiSquaresFourThin />
        </div>
      </div>
  );
};

export default NavBar;
