import styles from '../../styles/projectStyles/messageProjectCreatorStyles.module.scss'

// Redirect user to an application process

const ProjectApplication = ({ applicationProcess }) => {
    if (applicationProcess) {
        return (
            <button className={styles.messageButton}>
                Begin Application
            </button>
        );
    }
};

export default ProjectApplication;