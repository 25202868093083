import React, { useState } from "react";
import { CiMenuBurger, CiSearch } from "react-icons/ci";
import { MdFilterList } from "react-icons/md";
import NavBar from "../../components/navbar/NavBar";
import NavBarToggle from "../../components/navbar/NavBarToggle";
import styles from "../../styles/homeStyles/home.module.scss";
import { useAuth } from "../../firebaseAuth/authContext";
import defaultProfilePic from "../../assets/DefaultProfile.png";
import MyProjects from "./MyProjects";
import RecentArticles from "./RecentArticles";
import SearchTopics from "./SearchTopics";
import { Nav } from "react-bootstrap";

/*
Implement responsive design
*/

const Home = () => {
  const { authUser } = useAuth();
  const displayName =
      authUser && authUser.displayName ? authUser.displayName : "error loading";

  const [visible, setVisible] = useState(false);
  const toggle = () => {
    setVisible(prevState => !prevState);
  };

  if (authUser) {
    console.log(authUser.photoURL);
  }
  return (
      <div className={styles.containerHome}>
        <NavBarToggle toggle={toggle} visible={visible} />
        {visible ? (
            <NavBar />
        ) : (
            <>
              <div className={styles.containerHome__containerHeader}>
                <div className={styles.containerHome__userBox}>
                  <div className={styles.containerHome__user}>
                    <div className={styles.containerHome__userInfo}>
                      {authUser ? (
                          <img
                              className={styles.containerHome__imgUser}
                              src={authUser.photoURL}
                              alt="Profile"
                          />
                      ) : (
                          <img
                              src={defaultProfilePic}
                              alt="Profile"
                              className={styles.containerHome__imgUser}
                          />
                      )}

                      <div className={styles.containerHome__info}>
                        <p className={styles.containerHome__name}>{displayName}</p>
                        <p className={styles.containerHome__occupation}>Student</p>
                      </div>
                    </div>
                    <div className={styles.containerHome__iconMenu}>
                      <CiMenuBurger />
                    </div>
                  </div>
                </div>
                <SearchTopics />
              </div>

              <MyProjects authUser={authUser} />
              <RecentArticles authUser={authUser} />
            </>
        )}
      </div>
  );
};

export default Home;
