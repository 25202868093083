import React, { useEffect, useState } from "react";
import defaultProfilePic from "../../assets/DefaultProfile.png";
import styles from "../../styles/messagesStyles/header.module.scss";

const inactiveTimeout = 3 * 60000; //
const activeTimeout = 3 * 60000; //
const Header = ({ authUser }) => {
  const [isActive, setIsActive] = useState(true);
  const [inactivityTimer, setInactivityTimer] = useState(null);

  // Function to reset the inactivity timer
  const resetInactivityTimer = () => {
    if (inactivityTimer) {
      // If there's an existing timer, clear it
      clearTimeout(inactivityTimer);
    }
    // Set a new timer to mark the user as inactive after an idle period
    setInactivityTimer(
      setTimeout(() => {
        setIsActive(false);
      }, inactiveTimeout)
    );
  };

  const handleInteraction = () => {
    // Mark the user as active
    setIsActive(true);
    clearTimeout(inactivityTimer);
    resetInactivityTimer();
  };

  // Mouse move event handler
  const handleMouseMove = () => {
    handleInteraction();
  };

  // Key press event handler
  const handleKeyPress = () => {
    handleInteraction();
  };

  // Effect to check user activity and reset the inactivity timer
  useEffect(() => {
    // Set up an interval to check user activity
    const activityChecker = setInterval(() => {
      resetInactivityTimer(); // Check if the user is still active
    }, activeTimeout);

    // Add event listeners for mouse movement and key press
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("keypress", handleKeyPress);

    // Clean up event listeners and timer on component unmount
    return () => {
      clearInterval(activityChecker);
      if (inactivityTimer) {
        clearTimeout(inactivityTimer);
      }
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [inactivityTimer]); // inactivityTimer as dependency

  return (
    <div className={styles.messageContainer__header}>
      <div className={styles.messageContainer__user}>
        {authUser && (
          <>
            {authUser.photoURL ? (
              <img
                className={styles.messageContainer__profilePic}
                src={authUser.photoURL}
                alt="Profile"
              />
            ) : (
              <div>
                <img
                  src={defaultProfilePic}
                  alt="Profile"
                  className={styles.messageContainer__profilePic}
                />

                <div
                  className={`${styles.messageContainer__statusBubble} ${
                    isActive ? styles.active : styles.inactive
                  }`}
                ></div>
              </div>
            )}
            <div className={styles.messageContainer__infoUser}>
              <h2 className={styles.messageContainer__name}>
                {authUser.displayName}
                <p className={styles.messageContainer__occupation}>
                  {authUser.occupation ? authUser.occupation : "Student"}
                </p>
              </h2>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
