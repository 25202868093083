import React from "react";
import styles from "../../styles/footer.module.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer_container}>
      <div className={styles.copy_right}>
        <p>© {currentYear} ResearchHive</p>
        <div className={styles.info_container}>
          <ul className={styles.information_container}>
            <li>
              <a href="/about">About Us</a>
              <a href="/contact">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
