import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import {
  IoChevronForwardOutline,
  IoEyeOutline,
  IoNotificationsOutline,
  IoPeopleOutline,
  IoReturnUpBackOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import defaultProfilePic from "../../assets/DefaultProfile.png";
import { useAuth } from "../../firebaseAuth/authContext";

import NavBar from "../../components/navbar/NavBar";
import styles from "../../styles/profileStyles/profile.module.scss";

const Profile = () => {
  const { authUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("../Auth/login/LoginPage.jsx");
    } catch (error) {
      console.error("Error during logout: ", error);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.backgroundButton}></div>
        <button className={styles.backButton} onClick={goBack}>
          <i className={styles.iconHeader}>
            <IoReturnUpBackOutline />
          </i>
        </button>
        <h1 className={styles.username}>{authUser?.displayName || "Guest"}</h1>
        <button className={styles.gridButton}>
          <NavBar />
        </button>
      </div>

      <div className={styles.profileSection}>
        <div className={styles.profileInfo}>
          <img
            src={defaultProfilePic}
            alt="Profile-img"
            className={styles.profileImage}
          />
        </div>
        <div className={styles.userInfo}>
          <p className={styles.email}>
            {authUser ? authUser.email : "Not signed in"}
          </p>
          <p className={styles.role}>
            {authUser?.occupation ? authUser.occupation : "Student"}
          </p>
          <button className={styles.addBioButton}>+ Add bio</button>
        </div>
        <div className={styles.userActivity}>
          <div className={styles.userPost}>
            <p>Post</p>
            <p>{authUser ? authUser.postNumber : 0}</p>
          </div>
          <div className={styles.userFollowers}>
            <p>Followers</p>
            <p className={styles.followersNumber}>
              {authUser ? authUser.followers : 0}
            </p>
          </div>
          <div className={styles.userFollowing}>
            <p>Following</p>
            <p className={styles.followingNumber}>
              {authUser ? authUser.followingNumber : 0}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.buttons}>
          <button className={styles.editProfileButton}>Edit Profile</button>
          <button className={styles.shareProfileButton}>Share Profile</button>
        </div>
      </div>
      <div className={styles.backgroundNav}></div>
      <nav className={styles.navigation}>
        <div className={styles.buttonsContainers}>
          <button
            className={styles.navItem}
            onClick={() => handleNavigation("/settings")}
          >
            <i className={styles.navIcon}>
              <IoSettingsOutline />
            </i>
            Settings
            <i className={styles.arrow}>
              <IoChevronForwardOutline />
            </i>
          </button>
          <button
            className={styles.navItem}
            onClick={() => handleNavigation("/notifications")}
          >
            <i className={styles.navIcon}>
              <IoNotificationsOutline />
            </i>
            Notifications
            <i className={styles.arrow}>
              <IoChevronForwardOutline />
            </i>
          </button>
          <button
            className={styles.navItem}
            onClick={() => handleNavigation("/appearance")}
          >
            <i className={styles.navIcon}>
              <IoEyeOutline />
            </i>
            Appearance
            <i className={styles.arrow}>
              <IoChevronForwardOutline />
            </i>
          </button>
          <button
            className={styles.navItem}
            onClick={() => handleNavigation("/collab-manager")}
          >
            <i className={styles.navIcon}>
              <IoPeopleOutline />
            </i>
            Collab Manager
            <i className={styles.arrow}>
              <IoChevronForwardOutline />
            </i>
          </button>
          <button
            className={styles.navItem}
            onClick={() => handleNavigation("/about")}
          >
            <i className={styles.navIcon}>
              <AiOutlineQuestionCircle />
            </i>
            About
            <i className={styles.arrow}>
              <IoChevronForwardOutline />
            </i>
          </button>
        </div>
        <div className={styles.logoutContainer}>
          <button className={styles.logoutButton} onClick={handleLogout}>
            <i>
              <BiLogOut />
            </i>
            Logout
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Profile;
