import { useEffect, useState } from 'react';
import { useAuth } from '../../firebaseAuth/authContext';
import { useLocation } from 'react-router-dom';
import apiClient from '../../firebaseAuth/apiClient';
import styles from '../../styles/homeStyles/searchResults.module.scss';

const SearchResults = () => {
    const { authUser } = useAuth();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();
    const query = new URLSearchParams(location.search).get('query');

    useEffect(() => {
        const fetchResults = async () => {
            try {
                if (authUser) {
                    const response = await apiClient.get(`/projects/search/${encodeURIComponent(query)}`);
                    console.log(query);
                    setResults(response.data);
                    setLoading(false);
                }
            } catch (err) {
                setError(err.message || 'An error occurred');
                setLoading(false);
            }
        };

        fetchResults();
    }, [query]);

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error}</div>
    }

    return (
        <div className={styles.resultsContainer}>
            <h1>Search Results for "{query}"</h1>
            <ul>
                {results.length > 0 ? (
                    results.map((result) => (
                        <li key={result.id}>
                            <h2>{result.title}</h2>
                            <p>{result.description}</p>
                        </li>
                    ))
                ) : (
                    <div className={styles.noResults}>
                        <h2>No results found</h2>
                    </div>
                )}
            </ul>
        </div>
    );
};

export default SearchResults;