import { signInWithEmailAndPassword } from "firebase/auth";
import { React, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FaArrowRight } from "react-icons/fa";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { MdEmail, MdShield } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import LogoResearchHive from "../../../assets/LogoResearchHive.png";
import { useAuth } from "../../../firebaseAuth/authContext";
import { auth } from "../../../firebaseAuth/firebaseConfig";
import styles from "../../../styles/LoginPage.module.scss";

const LoginPage = () => {
  const { setAuthUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();

  const handleShowClick = () => setShowPassword(!showPassword);

  const signIn = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log("Login successful");
        const user = userCredential.user;

        setAuthUser(user);
        navigate("/home");
      })
      .catch((error) => {
        console.log(error);
        setLoginError(error.message);
      });
  };

  return (
    <div className={styles.containerLogin}>
      <div className={styles.containerLogo}>
        <div className={styles.circle_top}></div>
        <div className={styles.circle_top}></div>
        <div className={styles.logo_container}>
          <div className={styles.container_button_Back}>
            <button type="button" className={styles.button_Back}>
              <IoReturnUpBackOutline />
            </button>
          </div>
          <img src={LogoResearchHive} alt="Logo" className={styles.imgLogo} />
        </div>
        <p className={styles.textTitle}>Welcome to ResearchHive</p>
        <div className={styles.title_login_container}>
          <h2 className={styles.title}>
            Login <FaArrowRight className={styles.arrowIcon} />
          </h2>
        </div>
      </div>
      <div className={styles.containerForm}>
        <form onSubmit={signIn}>
          {loginError && (
            <div className={styles.error_message}>
              The username or password you entered is incorrect.
            </div>
          )}
          <div className={styles.userName}>
            <div className={styles.container_Input}>
              <p className={styles.Icon_Email}>
                <MdEmail />
              </p>
              <input
                className={styles.inputEmail}
                type="email"
                placeholder="Username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <a href="/forgot-username" className={styles.ForgotUser}>
              Forgot Username?
            </a>
          </div>
          <div className={styles.password}>
            <div className={styles.container_Input}>
              <button
                onClick={handleShowClick}
                className={styles.seePassword}
                type="button"
              >
                {showPassword ? (
                  <AiFillEyeInvisible size={20} />
                ) : (
                  <AiFillEye size={20} />
                )}
              </button>
              <input
                className={styles.inputPassword}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <a href="/forgot-password" className={styles.ForgotPassword}>
              Forgot Password?
            </a>
          </div>
          <div className={styles.container_BtnLogin}>
            <button type="submit" className={styles.Btn_Login}>
              LOGIN
            </button>
          </div>
        </form>
        <div className={styles.container_createAccount}>
          <hr />
          <button className={styles.btnCreateAccount}>
            <a href="/register">
              Create account <MdShield />
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
