import React, { useState } from 'react';
import { getTopicIcon, topicIconMapping } from './topicIcon';
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { CiSearch } from "react-icons/ci";
import { MdFilterList } from "react-icons/md";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import styles from '../../styles/homeStyles/searchTopics.module.scss';

const SearchTopics = () => {
    const topicsPerPage = 5;
    const topics = Object.keys(topicIconMapping);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate

    // Topics to be displayed on page
    const indexOfLastTopic = currentPage * topicsPerPage;
    const indexOfFirstTopic = indexOfLastTopic - topicsPerPage;
    const currentTopics = topics.slice(indexOfFirstTopic, indexOfLastTopic);

    // Search for topics (search bar)
    const handleSearch = () => {
        if (searchTerm.trim()) {
            // Go to results page
            navigate(`/searchResults?query=${encodeURIComponent(searchTerm)}`);
        }
    };

    // Handle topic button click
    const handleTopicClick = (topic) => {
        navigate(`/searchResults?query=${encodeURIComponent(topic)}`);
    };

    // Pagination
    const handleNextPage = () => {
        if (currentPage < Math.ceil(topics.length / topicsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Function to handle input change
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Function to handle key press event
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.searchBar}>
                <button className={styles.iconSearch} onClick={handleSearch}>
                    <CiSearch />
                </button>
                <input
                    type="text"
                    className={styles.inputSearch}
                    placeholder="Search Topic"
                    value={searchTerm}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
                <button className={styles.iconFilter}>
                    <MdFilterList />
                </button>
            </div>
            <div className={styles.topicsPaginationContainer}>
                <div className={styles.paginationControls}>
                    <button onClick={handlePrevPage} disabled={currentPage === 1}>
            <span className={styles.arrowLeft}>
              <SlArrowLeft />
            </span>
                    </button>
                </div>
                <div className={styles.topicsContainer}>
                    <div className={styles.buttonGrid}>
                        {currentTopics.map(topic => {
                            const Icon = getTopicIcon(topic);
                            return (
                                <button
                                    className={styles.topicButton}
                                    key={topic}
                                    onClick={() => handleTopicClick(topic)}
                                >
                                    <Icon className={styles.topicIcon} />
                                    <span className={styles.topicText}>{topic}</span>
                                </button>
                            );
                        })}
                    </div>
                </div>
                <div className={styles.paginationControls}>
                    <button onClick={handleNextPage} disabled={currentPage === Math.ceil(topics.length / topicsPerPage)}>
            <span className={styles.arrowRight}>
              <SlArrowRight />
            </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SearchTopics;
