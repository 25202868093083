import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiClient from '../../firebaseAuth/apiClient';
import { useAuth } from '../../firebaseAuth/authContext';
import ProjectCreatorProfile from '../../components/profileDisplays/ProjectCreatorProfile';
import MessageProjectCreator from './MessageProjectCreator';
import ProjectApplication from './ProjectApplication';
import RecentArticles from "../Home/RecentArticles";
// import 'primeicons/primeicons.css';
import styles from '../../styles/projectStyles/viewProjectStyles.module.scss';

const Project = () => {
    const { projectId } = useParams();
    const [project, setProject] = useState(null);
    const [projectCreatorDetails, setProjectCreatorDetails] = useState(null);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const { authUser } = useAuth();

    // Retrieves and sets data regarding the project the user is viewing
    useEffect(() => {
        const getProjectDetails = async () => {
            if (authUser) {
                try {
                    const response = await apiClient.get(`/projects/${projectId}`);
                    setProject(response.data);
                } catch (error) {
                    console.log(error);
                }
            }
        }

        getProjectDetails();
    }, [authUser, projectId]);

    // Retrives and sets data about the creator of the project the user is viewing
    useEffect (() => {
        const getProjectCreatorDetails = async () => {
            if (authUser && project) {
                try {
                    const response = await apiClient.get(`/user/${project.creator_uid}`);
                    setProjectCreatorDetails(response.data); 
                } catch (error) {
                    console.error(error);
                }
            }
        }

        if (project) {
            getProjectCreatorDetails();
        }
    }, [authUser, project]);

    const handleBookmarkClick = () => {
        setIsBookmarked(prevState => !prevState);
        // api call
    };

    return (
        <div>
            <div className={styles.containerHeader} />
            <div className={styles.projectContainer}>
                <div className={styles.project}>
                    {project ? (
                        <>
                            <div className={styles.symbols}>
                                <button 
                                    onClick={handleBookmarkClick}
                                    className={styles.bookmarkButton}
                                    aria-label={isBookmarked ? "Unbookmark" : "Bookmark"}
                                >
                                    <span 
                                        className={`pi ${isBookmarked ? 'pi-bookmark-fill' : 'pi-bookmark'}`} 
                                        style={{ fontSize: '2rem' }} 
                                    />
                                </button>
                                <button className={styles.bookmarkButton}>
                                    <span
                                        className='pi pi-share-alt'
                                        style={{ fontSize: '2rem' }}
                                    />
                                </button>
                            </div>
    
                            <h2 className={styles.title}>{project.title}</h2>
                            <ProjectCreatorProfile 
                                projectCreatorDetails={projectCreatorDetails} 
                                project={project} 
                            />
                            
                            <div className={styles.topicAndKeywordContainer}>
                                <p><b>topic(s):</b> {project.topics.join(', ')}</p>
                                <p><b>keywords:</b> {project.keywords.join(', ')}</p>
                            </div> 
    
                            <div className={styles.titleDescriptionContainer}>
                                <h3 className={styles.title}>About the project</h3>
                                <p style={{ whiteSpace: 'pre-wrap' }}>{project.description}</p>
                            </div>
    
                            <div className={styles.buttonsContainer}>
                                <MessageProjectCreator 
                                    declineMessaging={project.declineMessaging}
                                    authUser={authUser}
                                    creator_id={project.creator_uid}
                                />
                                <ProjectApplication
                                    applicationProcess={project.applicationProcess}
                                />
                            </div>
                        </>
                    ) : (
                        <h2>Error loading project</h2>
                    )}
                    {/* Add a 'similar projects' functionality instead of showing recent articles POTENTIALLY*/}
                    <RecentArticles 
                        authUser={authUser}
                    />
                </div>
            </div>
        </div>
    );
}

export default Project;