import React from "react";
import ReactDOM from "react-dom/client";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { AuthProvider } from "./firebaseAuth/authContext";
import "./index.css";
import { Register } from "./pages/Auth/Register/Register";
import LoginPage from "./pages/Auth/login/LoginPage";
import Home from "./pages/Home/Home";
import SearchResults from "./pages/Home/SearchResults";
import Landing from "./pages/landing/Landing";
import Message from "./pages/messages/Messages";
import MobileChat from "./pages/messages/MobileChat";
import Profile from "./pages/profile/Profile";
import Setting from "./pages/setting/Setting";
import Project from "./pages/project/Project";
import CreateProject from "./pages/project/CreateProject";
import Collaboration from "./pages/collaboration/collaboration";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="**" element={<Landing />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/settings" element={<Setting />}></Route>
          <Route path="/messages" element={<Message />} />
          <Route path="/mobileChats" element={<MobileChat />}></Route>
          <Route path="/projects/:projectId" element={<Project />} />
          <Route path="/searchResults" element={<SearchResults />} />
          <Route path="/projects/createNew" element={<CreateProject />} />
          <Route path="/collaboration" element={<Collaboration />} />
        </Routes>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
