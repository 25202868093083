import apiClient from '../../firebaseAuth/apiClient';
import { useNavigate } from 'react-router-dom'
import styles from '../../styles/projectStyles/messageProjectCreatorStyles.module.scss'

const MessageProjectCreator = ({declineMessaging, creator_id, authUser}) => {
    const navigate = useNavigate();
    // Prevents message button from rendering if messaging not enabled or authUser is the creator (can't message yourself)
    if (declineMessaging || creator_id == authUser.uid) {
        return;
    }

    const facilitateMessaging = async () => {
        const ids = {
            senderId: authUser.uid,
            receiverId: creator_id
        }

        // Create conversation between user and creator.
        const response = await apiClient.post('/messaging/conversation', ids);

        // Redirect user to messaging page
        if (response.status === 200 || response.status === 201) {
            navigate('/messages');
        } else {
            console.error("Error creating conversation", response.message);
        }
        
    }

    return (
        <button className={styles.messageButton} onClick={()=>facilitateMessaging()}>
            Message
        </button>
    );
};

export default MessageProjectCreator;