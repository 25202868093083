import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDGbnBi4vK3ewxDfF5B6UXSTd0gC78ERXU",
  authDomain: "researchive-f1242.firebaseapp.com",
  projectId: "researchive-f1242",
  storageBucket: "researchive-f1242.appspot.com",
  messagingSenderId: "270551869967",
  appId: "1:270551869967:web:895e811aa80ad0bab6d17a",
  measurementId: "G-QFRD6R9GQ1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
