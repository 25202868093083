import { TiDocumentText } from 'react-icons/ti';
import {
    FaCode,
    FaMicroscope,
    FaBook,
    FaFlask,
    FaGlobe,
    FaAtom,
    FaBrain,
    FaFirstAid,
    FaLeaf,
    FaStar,
    FaTools,
    FaCalculator,
    FaChartLine,
    FaBalanceScale,
    FaUsers,
    FaHistory,
    FaRobot,
    FaDatabase,
    FaMicrochip,
    FaDna,
    FaLanguage,
    FaGlobeAmericas,
    FaBriefcase,
    FaMoneyBill,
    FaBullhorn,
    FaGraduationCap,
    FaPills,
    FaSeedling,
    FaUtensils,
    FaCarrot,
    FaMountain,
    FaCloud,
    FaWater,
    FaFish,
    FaPaw,
    FaTree,
    FaRecycle,
    FaCity,
    FaBus,
    FaBuilding,
    FaHardHat,
    FaCog,
    FaBolt,
    FaSatelliteDish,
    FaCodeBranch,
    FaShieldAlt,
    FaCube,
    FaCoins,
    FaSmile,
    FaDesktop,
    FaVrCardboard,
    FaEye,
    FaGamepad,
    FaMusic,
    FaFilm,
    FaRunning,
    FaDumbbell,
    FaWheelchair,
    FaUserMd,
    FaComments,
    FaUserInjured,
    FaTooth,
    FaCapsules,
    FaUserNurse,
    FaBone,
    FaRocket,
    FaMeteor,
    FaScroll,
    FaPrayingHands,
    FaCross,
    FaDragon,
    FaHatWizard,
    FaBookOpen,
    FaLightbulb,
    FaVenusMars,
    FaRainbow,
    FaFemale,
    FaHandsHelping,
    FaGavel,
    FaUserSecret,
} from 'react-icons/fa';

export const topicIconMapping = {
    'computer science': FaCode,
    'biology': FaMicroscope,
    'literature': FaBook,
    'chemistry': FaFlask,
    'geography': FaGlobe,
    'physics': FaAtom,
    'psychology': FaBrain,
    'medicine': FaFirstAid,
    'environmental science': FaLeaf,
    'astronomy': FaStar,
    'engineering': FaTools,
    'mathematics': FaCalculator,
    'economics': FaChartLine,
    'political science': FaBalanceScale,
    'sociology': FaUsers,
    'history': FaHistory,
    'artificial intelligence': FaRobot,
    'machine learning': FaRobot,
    'data science': FaDatabase,
    'nanotechnology': FaMicrochip,
    'biochemistry': FaDna,
    'genetics': FaDna,
    'neuroscience': FaBrain,
    'robotics': FaRobot,
    'ethics': FaBalanceScale,
    'linguistics': FaLanguage,
    'anthropology': FaGlobeAmericas,
    'archaeology': FaGlobeAmericas,
    'business': FaBriefcase,
    'management': FaBriefcase,
    'finance': FaMoneyBill,
    'marketing': FaBullhorn,
    'advertising': FaBullhorn,
    'public relations': FaBullhorn,
    'law': FaBalanceScale,
    'education': FaGraduationCap,
    'chemistry': FaFlask,
    'materials science': FaFlask,
    'pharmacology': FaPills,
    'biotechnology': FaMicroscope,
    'agriculture': FaSeedling,
    'food science': FaUtensils,
    'nutrition': FaCarrot,
    'geology': FaMountain,
    'meteorology': FaCloud,
    'oceanography': FaWater,
    'marine biology': FaFish,
    'zoology': FaPaw,
    'botany': FaLeaf,
    'forestry': FaTree,
    'wildlife conservation': FaPaw,
    'environmental engineering': FaRecycle,
    'urban planning': FaCity,
    'transportation': FaBus,
    'architecture': FaBuilding,
    'urban design': FaCity,
    'civil engineering': FaHardHat,
    'mechanical engineering': FaCog,
    'electrical engineering': FaBolt,
    'telecommunications': FaSatelliteDish,
    'computer engineering': FaMicrochip,
    'software engineering': FaCodeBranch,
    'network security': FaShieldAlt,
    'cybersecurity': FaShieldAlt,
    'blockchain': FaCube,
    'cryptocurrency': FaCoins,
    'digital marketing': FaGlobe,
    'user experience (UX)': FaSmile,
    'user interface (UI)': FaDesktop,
    'virtual reality (VR)': FaVrCardboard,
    'augmented reality (AR)': FaEye,
    'game development': FaGamepad,
    'musicology': FaMusic,
    'film studies': FaFilm,
    'sports science': FaRunning,
    'kinesiology': FaDumbbell,
    'physical therapy': FaWheelchair,
    'occupational therapy': FaUserMd,
    'speech pathology': FaComments,
    'psychiatry': FaUserInjured,
    'dentistry': FaTooth,
    'pharmacy': FaCapsules,
    'nursing': FaUserNurse,
    'veterinary science': FaPaw,
    'paleontology': FaBone,
    'astronautics': FaRocket,
    'space exploration': FaRocket,
    'cosmology': FaMeteor,
    'astrobiology': FaMeteor,
    'philosophy': FaScroll,
    'ethics': FaBalanceScale,
    'theology': FaPrayingHands,
    'religious studies': FaCross,
    'mythology': FaDragon,
    'folklore': FaHatWizard,
    'linguistics': FaLanguage,
    'translation studies': FaLanguage,
    'literary theory': FaBookOpen,
    'critical theory': FaLightbulb,
    'gender studies': FaVenusMars,
    'queer studies': FaRainbow,
    'feminist studies': FaFemale,
    'social work': FaHandsHelping,
    'criminology': FaBalanceScale,
    'forensic science': FaGavel,
    'intelligence studies': FaUserSecret,
};

export const getTopicIcon = (topic) => {
    return topicIconMapping[topic] || TiDocumentText;
}