import axios from 'axios';
import { getIdToken } from './firebaseToken'

const apiClient = axios.create({
    baseURL: 'https://researchive.site/api'
});

apiClient.interceptors.request.use(async (config) => {
    const token = await getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

export default apiClient;