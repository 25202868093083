import axios from "axios";

import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
} from "firebase/auth";
import { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { IoReturnUpBackOutline } from "react-icons/io5";
import LogoResearchHive from "../../../assets/LogoResearchHive.png";
import styles from "../../../styles/Register.module.scss";
import {useNavigate} from "react-router-dom";

export const Register = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [register, setRegister] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    occupation: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { email, password, firstName, lastName, occupation } = register;

  const handleInputChange = ({ target }) => {
    setRegister({
      ...register,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createAccount(e);
  };

  const handleShowClick = () => setShowPassword(!showPassword);

  const createAccount = (e) => {
    e.preventDefault();

    // consider dedicated api call file(s)
    const addUserToDb = async (userAccountDetails) => {
      try {
        // POST userAccountDetails to backend - change route to env var on deploy
        const response = await axios.post(
          "https://localhost:443/api/addUser",
          userAccountDetails
        );
        console.log(response);
      } catch (error) {
        throw error;
      }
    };

    // Creates user using auth instance, email, and password. user contains user data.
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        console.log(`New user: ${user.uid}`);

        updateProfile(auth.currentUser, {
          displayName: `${firstName} ${lastName}`,
        }).catch((error) => {
          console.error("Error setting display name: ", error);
        });

        // structure for creating entry in database
        const userAccountDetails = {
          uid: user.uid,
          email: user.email,
          firstName: firstName,
          lastName: lastName,
          occupation: occupation,
        };

        addUserToDb(userAccountDetails);
      })
      .catch((error) => {
        setShowErrorMessage(true);
        if (error.code === "auth/email-already-in-use") {
          // account already exists. Notify user to sign in
          setErrorMessage("Account already exists. Please Log in.");
        } else if (error.code === "auth/invalid-email") {
          setErrorMessage(
            "Email invalid. Please ensure your email is correct."
          );
        } else if (error.code === "auth/weak-password") {
          setErrorMessage(
            "Password is too weak. Please strengthen your password."
          );
        }
      });
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate("/login")
  };

  return (
    <div className={styles.container_register}>
      <div className={styles.container_Formulario}>
        <div className={styles.containerLogo}>
          <div className={styles.container_button_Back}>
            <button className={styles.button_Back} onClick={handleBack}>
              <IoReturnUpBackOutline />
            </button>
          </div>
          <img src={LogoResearchHive} alt="" className={styles.imgLogo} />
          <h1 className={styles.title}>Create Account</h1>
          <p className={styles.textTitle}>Welcome to ResearchHive</p>
        </div>
        <div className={styles.container_form}>
          <form onSubmit={createAccount}>
            <div className={styles.error_message}>
              {showErrorMessage && <p>{errorMessage}</p>}
            </div>
            <div className={styles.firstName}>
              <div className={styles.container_Input}>
                <input
                  className={styles.inputForm}
                  placeholder="First Name"
                  value={firstName}
                  type="text"
                  name="firstName"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className={styles.lastName}>
              <div className={styles.container_Input}>
                <input
                  className={styles.inputForm}
                  placeholder="Last Name"
                  value={lastName}
                  type="text"
                  name="lastName"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className={styles.email}>
              <div className={styles.container_Input}>
                <input
                  className={styles.inputForm}
                  placeholder="Email"
                  value={email}
                  type="email"
                  name="email"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className={styles.password}>
              <div className={styles.container_Input}>
                <input
                  className={styles.inputForm}
                  placeholder="Password"
                  value={password}
                  type="password"
                  name="password"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className={styles.occupation}>
              <div className={styles.container_Input}>
                <input
                  className={styles.inputForm}
                  placeholder="Occupation"
                  value={occupation}
                  type="text"
                  name="occupation"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.container_Submit}>
        <div className={styles.container_BtnRegister}>
          <button className={styles.Btn_Register} onClick={handleSubmit}>
            Register
          </button>
        </div>
        <hr />
        <div className={styles.container_BtnRegister}>
          <button className={styles.Btn_google}>
            <FcGoogle />
          </button>
        </div>
      </div>
    </div>
  );
};
