import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import {useAuth} from "../../firebaseAuth/authContext";
import styles from "../../styles/collaborationStyles/collaboration.module.scss";
import NavBar from "../../components/navbar/NavBar";
import SearchTopics from "./SearchTopics";
import defaultProfilePic from "../../assets/DefaultProfile.png";
import apiClient from "../../firebaseAuth/apiClient";

const Collaboration = () => {
    const {authUser} = useAuth();
    const displayName = authUser && authUser.displayName ? authUser.displayName : "error loading";
    const navigate = useNavigate();

    const [collaborations, setCollaborations] = useState([]);
    const [creatorNames, setCreatorNames] = useState([]);

    useEffect(() => {
        const fetchCollaborations = async () => {
            try {
                if (authUser) {
                    const response = await apiClient.get(`/user/projects/collaborations/${authUser.uid}`);
                    setCollaborations(response.data);
                    await fetchCreatorNames(response.data);
                }
            } catch (err) {
                console.log("Error: User not logged in");
            }
        }

        const fetchCreatorNames = async (projects) => {
            const tempCreatorNames = {};
            for (const project of projects) {
                try {
                    const userResponse = await apiClient.get(`/user/${project.creator_uid}`);
                    tempCreatorNames[project.creator_uid] = userResponse.data.firstName + " " + userResponse.data.lastName;
                } catch (error) {
                    console.log("Error fetching creator name");
                }
            }
            setCreatorNames(tempCreatorNames);
        }

        fetchCollaborations()
            .catch(console.error)
    }, [authUser]);

    const handleClick = (projectId) => {
        navigate(`/projects/${projectId}`);
    }

    const collaborationsList = collaborations.map((project) => {
        return (
            <button key={project._id} className={styles.containerCollaboration_project} onClick={() => handleClick(project._id)}>
                <div className={styles.containerCollaboration_project_title}>{project.title}</div>
                <div className={styles.containerCollaboration_project_author}>{creatorNames[project.creator_uid]}</div>
            </button>
        );
    });

    return (
        <div className={styles.containerCollaboration}>
            {/*<NavBar/>*/}
            <div className={styles.containerCollaboration_header}>
                {authUser ? (
                    <img
                        className={styles.containerCollaboration_imgUser}
                        src={authUser.photoURL}
                        alt="Profile"
                    />
                ) : (
                    <img
                        src={defaultProfilePic}
                        alt="Profile"
                        className={styles.containerCollaboration_imgUser}
                    />
                )}
                <div className={styles.containerCollaboration_info}>
                    <p>{displayName}</p>
                    <p>Student</p>
                </div>
            </div>
            <header className={styles.containerCollaboration_title}>
                Collaborations
            </header>
            <div className={styles.containerCollaboration_search}>
                <SearchTopics/>
            </div>
            <div className={styles.containerCollaboration_projects}>
                {collaborationsList}
            </div>
        </div>
    );
};

export default Collaboration;
