import {onAuthStateChanged, signOut} from "firebase/auth";
import {doc, setDoc} from "firebase/firestore";
import {createContext, useContext, useEffect, useState} from "react";
import {auth, db} from "./firebaseConfig";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [userBio, setUserBio] = useState("");

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });
  }, []);

  const updateBio = async (newBio) => {
    if (authUser) {
      try {
        await setDoc(
          doc(db, "users", authUser.uid),
          { bio: newBio },
          { merge: true }
        );
        setUserBio(newBio);
      } catch (error) {
        console.error("Error updating bio: ", error);
      }
    }
  };
  const logout = async () => {
    try {
      await signOut(auth);
      setAuthUser(null);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ authUser, setAuthUser, userBio, updateBio, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => useContext(AuthContext);
