import React from "react";
import { CiSearch } from "react-icons/ci";
import styles from "./searchBar.module.scss";

const SearchBar = ({ value, onChange }) => {
  return (
    <div className={styles.searchBar}>
      <div className={styles.searchBar__searchIcon}>
        <span className={styles.searchBar__icon}>
          <CiSearch />
        </span>
      </div>
      <input
        type="text"
        placeholder="Search"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchBar;
