import React, { useCallback, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import openChatImage from "../../assets/openChatImage.svg";
import SearchBar from "../../components/searchBar/SearchBar";
import { useAuth } from "../../firebaseAuth/authContext";
import styles from "../../styles/messagesStyles/Message.module.scss";
import Header from "../messages/Header";
import Chatbox from "./Chatbox";
import ConversationList from "./ConversationList";
import apiClient from "../../firebaseAuth/apiClient";
// API URL
const API_URL = "/user/getConversations";

// Custom hook to handle conversations
const useConversations = (authUser) => {
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    const retrieveConversations = async () => {
      try {
        if (authUser) {
          const uid = { uid: authUser.uid };
          const response = await apiClient.post(API_URL, uid);
          setConversations(response.data);
        }
      } catch (error) {
        console.error("Error retrieving conversations:", error);
      } finally {
        setLoading(false);
      }
    };

    if (authUser) {
      retrieveConversations();
    }
  }, [authUser]);

  return { loading, conversations, setConversations };
};

// Custom hook to handle screen size
const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
};

// Main Message component
const Message = () => {
  const { authUser } = useAuth();
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const { loading, conversations, setConversations } =
    useConversations(authUser);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  let currentUserId;
  if(authUser) {
    currentUserId = authUser.uid;
  }

  // Function to handle conversation click
  const handleConversationClick = useCallback(
    (conversation) => {
      navigate("/mobileChats", { state: conversation });
    },
    [navigate]
  );

  // Function to update conversation
  const updateConversation = useCallback(
    (updatedConversation) => {
      setConversations((prevConversations) =>
        prevConversations.map((conv) =>
          conv.conversationId === updatedConversation.conversationId
            ? updatedConversation
            : conv
        )
      );
    },
    [setConversations]
  );

  // Function to close chatbox
  const handleCloseChatbox = () => {
    setSelectedConversation(null);
  };

  // Render chatBox if a conversation is selected
  const renderChatBox = selectedConversation && (
    <Chatbox
      conversation={selectedConversation}
      updateConversation={updateConversation}
    />
  );

  //Render open chat message if no conversation is selected or chatbox is closed
  const renderOpenChatMessage = !selectedConversation && (
    <div className={styles.openChatMessage}>
      <img
        className={styles.openChatMessage__img}
        src={openChatImage}
        alt="Open Chat"
      />
      <h1>ResearchHive</h1>
      <div className={styles.openChatMessage__text}>
        <span className={styles.openChatMessage__textSpan}>
          In diversity of ideas lies true academic growth.
        </span>
        <p className={styles.openChatMessage__textP}>
          Contribute your unique voice and perspectives!
        </p>
      </div>
    </div>
  );

  // If loading, show loading indicator
  if (loading) {
    return <div>Loading...</div>;
  }

  // Filter conversations based on search term
  const filteredConversations = conversations.filter((conversation) =>
    conversation.otherUserFirstName
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {screenSize > 1020 ? (
        <section className={styles.messageContainer}>
          <header className={styles.messageContainer__headerContainer}>
            <Header authUser={authUser} />
            {/* Conversation search bar */}
            <div className={styles.messageContainer__searchBarBox}>
              <div>
                <SearchBar
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.messageContainer__conversationList}>
              {/* List of filtered conversations */}
              <ConversationList
                conversations={filteredConversations}
                onSelectConversation={setSelectedConversation}
                currentUserId={currentUserId}
              />
            </div>
          </header>
          <div className={styles.messageContainer__mainChatBox}>
            {renderChatBox && (
              <button onClick={handleCloseChatbox}>
                <IoMdClose />
              </button>
            )}
            {renderChatBox || renderOpenChatMessage}
          </div>
        </section>
      ) : (
        <div className={styles.messageContainerModal}>
          <header className={styles.messageContainerModal__headerContainer}>
            <Header authUser={authUser} />
          </header>
          <div className={styles.messageContainerModal__searchBarBox}>
            <div className={styles.messageContainerModal__searchBar}>
              <SearchBar
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.messageContainerModal__conversationList}>
            {/* List of filtered conversations */}
            <ConversationList
              conversations={filteredConversations}
              onSelectConversation={handleConversationClick}
              currentUserId={currentUserId}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Message;
