import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../firebaseAuth/apiClient';
import styles from '../../styles/homeStyles/projects-list.module.scss';
import { CiCirclePlus } from "react-icons/ci";
import { SlArrowRight } from "react-icons/sl";
import { getTopicIcon } from './topicIcon';

// Fetches and displays list of projects the current user has created
const MyProjects = ({ authUser }) => {
    const [userProjects, setUserProjects] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserProjects = async () => {
            if (authUser) {
                try {
                    const response = await apiClient.get(`/user/projects/${authUser.uid}`);
                    setUserProjects(response.data);
                } catch (error) {
                    console.error('Error fetching projects:', error);
                }
            }
        };

        fetchUserProjects();
    }, [authUser]);

    const handleClick = (projectId) => {
        navigate(`/projects/${projectId}`);
    }

    const navigateCreateProject = () => {
        navigate(`/projects/createNew`);
    }

    const projectsList = userProjects.map((project) => {
        const projectIcon = getTopicIcon(project.mainTopic);

        return (
            <button key={project._id} className={styles.projectContainer} onClick={() => handleClick(project._id)}>
                <div className={styles.topicContainer}>
                    {project.mainTopic || project.topics[0]}
                </div>
                <div>
                    {React.createElement(projectIcon, { className: styles.projectIcon })}
                </div>
                <h3 className={styles.projectTitle}>{project.title}</h3>
            </button>
        );
    });
    
    const createProject = (
        <button className={styles.createProjectContainer} onClick={() => navigateCreateProject()}>
            <h3>Create New Project</h3>
            <CiCirclePlus className={styles.circlePlus} />
        </button>
    );

    return (
        <div>
            <div className={styles.titleButtonContainer}>
                <h2 style={{ color: 'white' }}>My Projects</h2>
                {userProjects && userProjects.length > 4 && (
                    <button className={styles.nextButton} >
                        <SlArrowRight />
                    </button>
                )}
            </div>
            <div className={styles.projectsContainer}>
                {projectsList}
                {createProject}
            </div>
        </div>
    );
}

export default MyProjects;