import React from "react";
import ConversationTile from "./conversationTile";

// lists tile objects representing each conversation
const ConversationList = ({ conversations, onSelectConversation, currentUserId }) => {
  // specific conversation is selected -> chat box opens
  const handleConversationClick = (conversation) => {
    onSelectConversation(conversation);
  };

  // sorts conversation by conversation.latestMessageTimeStamp
  const sortedConversations = conversations.sort((conv_a, conv_b) => {
    const date_a = new Date(conv_a.latestMessageTimeStamp);
    const date_b = new Date(conv_b.latestMessageTimeStamp);

    return date_b - date_a;
  });

  return (
    <div>
      {sortedConversations &&
        sortedConversations.map((conversation, index) => (
          <ConversationTile
            key={index}
            conversation={conversation}
            onClick={() => handleConversationClick(conversation)}
            currentUserId={currentUserId}
          />
  ))}
    </div>
  );
};

// style/html guide for individual conversation titles

export default ConversationList;
