import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../firebaseAuth/authContext";
import apiClient from "../../firebaseAuth/apiClient";
import styles from '../../styles/projectStyles/createProjectStyles.module.scss';

const CreateProject = () => {
    const navigate = useNavigate();
    const { authUser } = useAuth();
    const [title, setTitle] = useState();
    const [keywords, setKeywords] = useState([]);
    const [currentKeyword, setCurrentKeyword] = useState();
    const [topics, setTopics] = useState([]);
    const [currentTopic, setCurrentTopic] = useState();
    const [description, setDescription] = useState();
    const [declineMessaging, setDeclineMessaging] = useState(false);
    const [applicationProcess, setApplicationProcess] = useState(false);
    const [jobPosition, setJobPosition] = useState(false);

    // For displaying error messages to user
    const [titleError, setTitleError] = useState("");
    const [keywordsError, setKeywordsError] = useState("");
    const [topicsError, setTopicsError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");

    // Adds current keyword to keywords array
    const addKeywordClick = () => {
        if (keywords.includes(currentKeyword) || currentKeyword.trim() === "") {
            setCurrentKeyword("");
            return;
        }

        setKeywords([...keywords, currentKeyword.trim()]);
        setCurrentKeyword("");
    };

    // Adds current topic to topics array
    const addTopicClick = () => {
        if (topics.includes(currentTopic) || currentTopic.trim() === "") {
            setCurrentTopic("");
            return;
        }
        setTopics([...topics, currentTopic.trim()]);
        setCurrentTopic("");
    }

    // Checks inputs to ensure correctness
    const validateForm = async () => {
        let valid = true;
        // Check that title is not empty
        if (!title || title.trim() === "") {
            setTitleError("Please enter a title.");
            valid = false;
        }
        // Check if topic(s) have been added
        else if (topics.length <= 0) {
            setTopicsError("Please add some topics.");
            valid = false;
        }
        // Check if keyword(s) have been added
        else if (keywords.length <= 0) {
            setKeywordsError("Please add some keywords.");
            valid = false;
        }
        // Check that description is not empty
        else if (!description || description.length < 50 || description.length > 3000) {
            setDescriptionError("Description must be between 50 and 3000 characters.");
            valid = false;
        }

        if (!valid) return;

        if (jobPosition) {
            setKeywords((keywords) => ["Research Opportunity", ...keywords]);
        }

        const formData = {
            creator_uid: authUser.uid,
            title: title.trim(),
            keywords: keywords,
            topics: topics,
            description: description.trim(),
            declineMessaging: declineMessaging,
            applicationProcess: applicationProcess
        };

        try {
            const response = await apiClient.post(`/user/projects/${authUser.uid}`, formData);
            console.log(response);
            navigate("/home");
        } catch (error) {
            console.error('Error submitting form: ', error);
        }
    }

    return (
        <div className={styles.createProjectContainer}>
            <h2>Create New Research Project</h2>
            <form className={styles.createProjectContainer__form}>
                <label>
                    <h3>Research Project Title:<br /></h3>
                    {titleError && <p className={styles.error}>{titleError}</p>}
                    <textarea
                        className={styles.createProjectContainer__titleTextArea}
                        id="title"
                        placeholder="Enter research project title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </label>

                <label>
                    <input 
                        type="checkbox" 
                        id="jobPosition" 
                        checked={jobPosition} 
                        onChange={(e) => setJobPosition(e.target.checked)} 
                    />
                    This is an employment opportunity.
                </label>

                <label>
                    <h3>Topics:</h3>
                    <div className={styles.createProjectContainer__addWordsTopics}>
                        {topicsError && <p className={styles.error}>{topicsError}</p>}
                        <textarea
                            className={styles.createProjectContainer__submitTextArea}
                            placeholder="Enter topic (Example: Geography)"
                            value={currentTopic}
                            onChange={(e) => setCurrentTopic(e.target.value)}
                        />
                        <button 
                            className={styles.createProjectContainer__addButton}
                            onClick={addTopicClick}
                            type="button"
                        >
                            Add
                        </button>
                    </div>
                    <ul>
                        {topics.length > 0 ? (
                            topics.map((topic, index) => (
                                <li key={index}>{topic}</li>
                            ))
                        ) : (
                            <p>No topics yet</p>
                        )}
                    </ul>
                </label>

                <label>
                    <h3>Keywords:</h3>
                    {keywordsError && <p className={styles.error}>{keywordsError}</p>}
                    <div className={styles.createProjectContainer__addWordsTopics}>
                        <textarea
                            className={styles.createProjectContainer__submitTextArea}
                            placeholder="Enter keyword (Example: Coastal Erosion)"
                            value={currentKeyword}
                            onChange={(e) => setCurrentKeyword(e.target.value)}
                        />
                        <button 
                            className={styles.createProjectContainer__addButton}
                            onClick={addKeywordClick}
                            type="button"
                        >
                            Add
                        </button>
                    </div>
                    <ul>
                        {keywords.length > 0 ? (
                            keywords.map((keyword, index) => (
                                <li key={index}>{keyword}</li>
                            ))
                        ) : (
                            <p>No keywords yet</p>
                        )}
                    </ul>
                </label>

                <label>
                    <h3>Description or Summary:</h3>
                    {descriptionError && <p className={styles.error}>{descriptionError}</p>}
                    <textarea
                        className={styles.createProjectContainer__descriptionTextArea}
                        id="description"
                        placeholder="Enter description or summary"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </label>

                <label>
                    <input 
                        type="checkbox" 
                        id="declineMessaging" 
                        checked={declineMessaging} 
                        onChange={(e) => setDeclineMessaging(e.target.checked)} 
                    />
                    I would NOT like to be messaged by other users about this post.
                </label>

                <label>
                    <input 
                        type="checkbox" 
                        id="applicationProcess" 
                        checked={applicationProcess} 
                        onChange={(e) => setApplicationProcess(e.target.checked)} 
                    />
                    I would like users to submit an application in order to inquire about this post.
                </label>

                <button 
                    className={styles.createProjectContainer__submitFormButton}
                    type="button"
                    onClick={validateForm}
                >
                    Submit
                </button>
            </form>
        </div>
    );
}

export default CreateProject;