import React, { useEffect, useState } from 'react';
import { useAuth } from '../../firebaseAuth/authContext';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../firebaseAuth/apiClient';
import styles from '../../styles/homeStyles/projects-list.module.scss';
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { getTopicIcon } from './topicIcon';

/*
Lazy loading - if 5 projects not available to show, start adding previously seen ones
*/

// Fetches and displays recently added projects from the database
const RecentArticles = ({ authUser }) => {
    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const fetchProjects = async () => {
        setLoading(true);
        if (authUser) {
            try {
                const response = await apiClient.get(`/lazy/projects?page=${page}&limit=5`);
                setProjects(prevProjects => [...response.data]);
            } catch (error) {
                console.error('Error fetching projects:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const loadNextPage = () => {
        setPage(prevPage => prevPage + 1);
    }

    const loadPrevPage = () => {
        setPage(prevPage => prevPage - 1);
    }

    const handleClick = (projectId) => {
        navigate(`/projects/${projectId}`);
    }

    useEffect(() => {
        fetchProjects();
    }, [authUser, page]);

    const projectsList = projects.map((project) => {
        const projectIcon = getTopicIcon(project.mainTopic);

        return (
            <button key={project._id} className={styles.projectContainer} onClick={() => handleClick(project._id)}>
                <div className={styles.topicContainer}>
                    {project.mainTopic || project.topics[0]}
                </div>
                <div>
                    {React.createElement(projectIcon, { className: styles.projectIcon })}
                </div>
                <hr className={styles.separator} /> 
                <h3 className={styles.projectTitle}>{project.title}</h3>
            </button>
        );
    });

    return (
        <div>
            <div className={styles.titleButtonContainer}>
                {page !== 1 &&
                    <button onClick={loadPrevPage} className={styles.nextButton}>
                        <SlArrowLeft />
                    </button>
                }
                <h2 style={{ color: 'white' }}>Recent Articles</h2>
                {loading && <p>Loading...</p>}
                {page <= projects.length / 5 &&
                    <button onClick={loadNextPage} className={styles.nextButton}>
                        <SlArrowRight />
                    </button>
                }
            </div>
            <div className={styles.projectsContainer}>
                {projectsList}
            </div>
        </div>
    );
}

export default RecentArticles;