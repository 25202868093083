import React, { useEffect, useRef, useState } from "react";
import defaultOtherProfilePic from "../../assets/DefaultProfile.png";
import { useAuth } from "../../firebaseAuth/authContext";
import styles from "../../styles/messagesStyles/chatBox.module.scss";
import { socket } from "./socketHelper";
import apiClient from '../../firebaseAuth/apiClient';

const Chatbox = ({ conversation, updateConversation }) => {
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [isChatOpen, setIsChatOpen] = useState(true);
  const { authUser } = useAuth();
  const messagesEndRef = useRef();

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString(); // Format the date as needed
  };

  // Convert UTC to local time
  const convert_UTC_to_local = (UTC) => {
    try {
      const localTime = new Date(UTC);

      const time = localTime.toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
      });

      const month = localTime.toLocaleString("default", { month: "short" });
      const day = localTime.getDate();
      const year = localTime.getFullYear();

      // return `${time} ${month} ${day}, ${year}`;
      return time;
    } catch (error) {
      console.error("Error converting time: ", error.message);
    }
  };
  // Form submission: uses text submitted to create new message obj, then emits to server-side WebSocket
  const submitMessage = (event) => {
    event.preventDefault();
    if (!messageInput.trim()) return;

    const newMessage = {
      timestamp: new Date().toISOString(),
      senderId: authUser?.uid,
      receiverId: conversation.otherUserId,
      message: messageInput,
    };

    try {
      // Alerts WS server message was sent from client
      socket.emit("send_message", newMessage);
      setMessageInput("");
    } catch (error) {
      console.error("Error sending msg: ", error);
    }
  };

  // Chatbox scrolled to bottom when started/message received or sent
  useEffect(() => {
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    scrollToBottom();
  }, [messages]);

  // Fetches list of messages present in the specific conversation
  useEffect(() => {
    const getConversationMessages = async () => {
      try {
        console.log("Messages set from API call");
        const conversationId = conversation.conversationId;
        socket.emit("join_conversation", conversationId);
        const response = await apiClient.get(`/messaging/conversation/messages/${conversationId}`);
        setMessages(response.data);
      } catch (error) {
        console.error({ message: error });
      }
    };
    getConversationMessages();
  }, [conversation.conversationId]);

  // Updates conversation messages when new message received
  useEffect(() => {
    const handleNewMessage = (message) => {
      // Update messages to include the new message
      console.log("New message: ", message);
      setMessages((prevMessages) => [...prevMessages, message]);

      updateConversation({
        ...conversation,
        latestMessage: message.message,
        latestMessageSenderFirstName:
          message.senderId === authUser?.uid
            ? "You"
            : conversation.otherUserFirstName,
      });
      console.log("user", conversation);
    };

    socket.on("new_message", handleNewMessage);

    return () => {
      socket.off("new_message", handleNewMessage);
    };
  }, [authUser, conversation, updateConversation]);

  // Check if authUser is null or undefined
  if (!authUser) {
    return <div>Login required</div>;
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      submitMessage(event);
    }
  };

  return (
    <section className={styles.ChatBoxContainer}>
      <header className={styles.ChatBoxContainer__header}>
        <img
          className={styles.ChatBoxContainer__OtherUserImage}
          src={conversation.otherUserImage || defaultOtherProfilePic}
          alt="Other User"
        />
        <h3>{conversation.otherUserFirstName}</h3>
      </header>
      <div className={styles.ChatBoxContainer__chatBox}>
        <div className={styles.ChatBoxContainer__chatBoxMessages}>
          {messages &&
            messages.map((message, index) => {
              const currentMessageDate = new Date(message.timestamp).toDateString();
              const previousMessageDate = index > 0 ? new Date(messages[index - 1].timestamp).toDateString() : null;
              const showDate = currentMessageDate !== previousMessageDate;
  
              return (
                <React.Fragment key={index}>
                  {showDate && (
                    <div className={styles.ChatBoxContainer__dateSeparatorWrapper}>
                      <div className={styles.ChatBoxContainer__dateSeparator}>
                        {formatDate(message.timestamp)}
                      </div>
                    </div>
                  )}
                  <div
                    className={`${styles.ChatBoxContainer__messageBox} ${
                      message.senderId === authUser?.uid ? "" : styles.received
                    }`}
                  >
                    <div className={styles.ChatBoxContainer__chatMessage}>
                      <p>
                        <span>{message.message}</span>
                      </p>
                      <p>{convert_UTC_to_local(message.timestamp)}</p>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          <div ref={messagesEndRef} />
        </div>
        <div>
          <form
            className={styles.ChatBoxContainer__form}
            onSubmit={submitMessage}
          >
            <textarea
              className={styles.ChatBoxContainer__textArea}
              id="messageInput"
              placeholder="Type message"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Chatbox;
