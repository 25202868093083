import React from "react";
import { IoMdClose } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import styles from "../../styles/messagesStyles/mobile.module.scss";
import Chatbox from "./Chatbox";
const MobileChat = ({ updateConversation }) => {
  const location = useLocation();
  const { state: conversationData } = location;

  return (
    <div className={styles.container}>
      <Link to="/messages" style={{ textDecoration: "none" }}>
        <button>
          <IoMdClose />
        </button>
      </Link>
      {conversationData ? (
        <Chatbox
          conversation={conversationData}
          updateConversation={updateConversation}
        />
      ) : (
        <p style={{ color: "white" }}>No conversation selected.</p>
      )}
    </div>
  );
};

export default MobileChat;
