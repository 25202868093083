import React, {useEffect, useState} from "react";
import {IoReturnUpBackOutline} from "react-icons/io5";
import img from "../../assets/AboutSection.png";
import styles from "../../styles/settings/setting.module.scss";
import apiClient from "../../firebaseAuth/apiClient";
import {useAuth} from "../../firebaseAuth/authContext";

const Setting = () => {
    const {authUser} = useAuth();
    const [activeTab, setActiveTab] = useState("upload");
    const [selectedFiles, setSelectedFiles] = useState({
        resume: null,
        work_papers: null,
        certificates: null
    });
    const [files, setFiles] = useState({
        resume: null,
        work_papers: null,
        certificates: null
    })

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                if (authUser) {
                    const response = await apiClient.get(`/files/${authUser.uid}`);
                    const base64ToURL = (base64String) => {
                        const byteCharacters = atob(base64String);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: 'application/pdf' });
                        return URL.createObjectURL(blob);
                    }
                    const resume = base64ToURL(response.data.resume);
                    const work_papers = base64ToURL(response.data.work_papers);
                    const certificates = base64ToURL(response.data.certificates);
                    setFiles({
                        resume: resume,
                        work_papers: work_papers,
                        certificates: certificates
                    })
                }
            } catch (err) {
                console.log("Error: User not logged in", err);
            }
        }
        fetchFiles().catch(console.error)
    }, [authUser]);

    const handleChange = (event, label) => {
        const file = event.target.files[0];
        setSelectedFiles({
            ...selectedFiles,
            [label]: file,
        });
    };

    const handleUpload = async () => {
        const formData = new FormData();

        formData.append("uid", authUser.uid)
        formData.append("files", selectedFiles.resume);
        formData.append("files", selectedFiles.work_papers);
        formData.append("files", selectedFiles.certificates);

        await apiClient.post("/files", formData)
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.settingsContainer}>
                {/* Header Section */}
                <div className={styles.headerContainer}>
                    <div className={styles.header}>
                        <button className={styles.backButton}>
                            <IoReturnUpBackOutline />
                        </button>
                        <h1>Add Settings</h1>
                        {/*<Navbar />*/}
                    </div>

                    {/* Tabs */}
                    <div className={styles.tabs}>
                        <button
                            className={activeTab === "upload" ? styles.activeTab : ""}
                            onClick={() => setActiveTab("upload")}
                        >
                            Upload Data
                        </button>
                        <button
                            className={activeTab === "questions" ? styles.activeTab : ""}
                            onClick={() => setActiveTab("questions")}
                        >
                            Questions
                        </button>
                    </div>
                </div>

                {/* Content Based on Active Tab */}
                {activeTab === "upload" ? (
                    <div className={styles.uploadData}>
                        <div>
                            <a href={files.resume} download="resume.pdf">
                                Download Resume
                            </a>
                        </div>
                        <div>
                            <a href={files.work_papers} download="work_papers.pdf">
                                Download Work Papers
                            </a>
                        </div>
                        <div>
                            <a href={files.certificates} download="certificates.pdf">
                                Download Certificates
                            </a>
                        </div>
                        <CheckboxItem label={"resume"} onFileChange={handleChange} />
                        <CheckboxItem label={"work_papers"} onFileChange={handleChange} />
                        <CheckboxItem label={"certificates"} onFileChange={handleChange} />
                    </div>
                ) : (
                    <div className={styles.questions}>
                        {/* Questions related content can go here */}
                    </div>
                )}

                {/* Save Button */}
                <div className={styles.saveContainer}>
                    <button className={styles.saveButton} onClick={handleUpload}>
                        Save Settings
                    </button>
                </div>
            </div>
            <div className={styles.imgContainer}>
                <img src={img} alt="About Section" />
            </div>
        </div>
    );
};

const CheckboxItem = ({label, onFileChange}) => {
    return (
        <form>
            <h3>Upload {label}</h3>
            <input type="file" onChange={(event) => onFileChange(event, label)} />
        </form>
    );
};

export default Setting;
