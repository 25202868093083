import React from 'react';
import { formatUTCDate } from '../../utils/timeConversions';
import styles from '../../styles/projectStyles/projectCreatorProfileStyles.module.scss';

const ProjectCreatorProfile = ({ projectCreatorDetails, project }) => {
    if (projectCreatorDetails) {
        console.log(projectCreatorDetails.imageUrl)
        return (
            <div className={styles.profileContainer}>
                <div className={styles.publishedBy}>
                    <p><b>Published by:</b></p>
                </div>

                <div className={styles.profileSection}>
                    <img
                        className={styles.profilePicture}
                        src={projectCreatorDetails.imageUrl}
                        alt="Profile"
                    />
                    <div className={styles.colu}>
                        <div className={styles.roww}>
                            <p className={styles.name}>
                                {projectCreatorDetails.firstName} {projectCreatorDetails.lastName}
                            </p>

                            {/* Separator line */}
                            <div className={styles.separatorLine}></div>

                            {/* Occupation and organization */}
                            <div className={styles.occupationOrganization}>
                                {projectCreatorDetails.occupation && (
                                    <p className={styles.occupation}>
                                        {projectCreatorDetails.occupation} 
                                        {projectCreatorDetails.organization && ' at'}
                                    </p>
                                )}
                                {projectCreatorDetails.organization && (
                                    <p>{projectCreatorDetails.organization}</p>
                                )}
                            </div>
                        </div>
                        <p>{formatUTCDate(project.created)}</p>
                    </div>
                </div>
            </div>
        );
    } else {
        return <h2>Error loading project creator details</h2>;
    }
};

export default ProjectCreatorProfile;