import React from "react";
import AboutSection from "../../assets/AboutSection.png";
import LogoResearchHive from "../../assets/LogoResearchHive.png";
import styles from "../../styles/landing.module.scss";
import Footer from "../../components/footer/Footer"

const Landing = () => {
  return (
    <div>
      <header className={styles.header_container}>
        <div className={styles.logo_container}>
          <a href="" className={styles.Logo}>
            <img src={LogoResearchHive} alt="ResearchHive Logo" />
          </a>
          <div className={styles.LoginButton}>
            <a href="/login">
              <button className={styles.btn_login}>Login</button>
            </a>
          </div>
        </div>
        {/* title and subtitle */}
        <section className={styles.title_container}>
          <h1 className={styles.researchHive_title}>ResearchHive</h1>
          <h2 className={styles.researchHive_subtitle}>
            Connect with others and collaborate on research
          </h2>
          <div className={styles.about_second_subtitle}>
            <h2>About Us</h2>
          </div>
        </section>
      </header>
      {/* about us section  */}

      
      <main>
        <article className={styles.aboutUs_container}>
          <div className={styles.about_text}>
            <p>
              ResearcHive is a web application that allows educators,
              researchers, and students to connect and collaborate on writing
              research papers.
            </p>
            <div className={styles.img_about_container}>
              <div className={styles.img_container}>
                <img src={AboutSection} alt="AboutSection-img" />
              </div>
            </div>
            <div className={styles.getStarted_container}>
              <div className={styles.getStarted_button}>
                <button>Get started</button>
              </div>
            </div>
          </div>
        </article>
        <div className={styles.rectangle_container_left}></div>
        <div className={styles.circle_container_top_left}></div>
      </main>
        <Footer />
    </div>
  );
};

export default Landing;
