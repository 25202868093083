import React from "react";

const NavbarToggle = ({toggle, visible}) => {
    return (
        <button onClick={toggle}>
            {visible ? "Hide Navbar" : "Show Navbar"}
        </button>
    );
};

export default NavbarToggle;
