import React, { useState } from "react";
import styles from "../../styles/messagesStyles/conversationTile.module.scss";
import defaultOtherProfilePic from "../../assets/DefaultProfile.png";
import apiClient from "../../firebaseAuth/apiClient";

const truncateMessage = (message, maxLength) => {
  if (message) {
    if (message.length <= maxLength) {
      return message;
    } else {
      return message.slice(0, maxLength) + "...";
    }
  }
};

// API call to edit conversation viewed array -> changes conversation to "seen"
const setViewed = async (viewedArray, conversationId) => {
  try {
    const API_URL = `https://localhost:443/api/messaging/conversation/${conversationId}`;
    const body = {
      viewed: viewedArray
    }

    const response = await apiClient.patch(API_URL, body);
    console.log(response.status);
  } catch (error) {
    throw error;
  }
}

const ConversationTile = ({ conversation, onClick, currentUserId }) => {
  const truncatedMessage = truncateMessage(conversation.latestMessage, 30);
  
  // determine if conversation has been viewed by user before
  const userOneIndex = conversation.otherUserId === currentUserId ? 1 : 0;
  const initialButtonClass = conversation.viewed[userOneIndex] ? styles.conversationTile__buttonMessages : styles.conversationTile__buttonMessages_not_read;

  const [buttonClass, setButtonClass] = useState(initialButtonClass);

  // Calls the parent onClick function, and current file click functions
  const handleClick = async () => {
    onClick();
    setButtonClass(styles.conversationTile__buttonMessages);

    const viewedArray = [...conversation.viewed]
    // Check if viewed array element is true (conversation is already seen)
    if (!viewedArray[userOneIndex]) {
      viewedArray[userOneIndex] = true;
      setViewed(viewedArray, conversation.conversationId);
    }
  };

  return (
    <div className={styles.conversationTile}>
      <button
        className={buttonClass}
        onClick={handleClick}
      >
        <div className={styles.conversationTile__buttonContent}>
          <img
            className={styles.conversationTile__userImage}
            src={conversation.otherUserPhotoURL || defaultOtherProfilePic}
            alt="Other User"
          />
          <div className={styles.conversationTile__text}>
            <div className={styles.conversationTile__buttonName}>
              <p>{conversation.otherUserFirstName} {conversation.otherUserLastName}</p>
            </div>
            <div className={styles.conversationTile__latestMessage}>
              {conversation.latestMessageSenderFirstName ? (
                <p>
                  {conversation.latestMessageSenderFirstName}:{" "}
                  {truncatedMessage}
                </p>
              ) : (
                <p>Send a message.</p>
              )}
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};

export default ConversationTile;